import styles from "./StandardCard.module.scss";
import { useRouter } from "next/router"
import { Base64 } from "js-base64";
import axios from "axios";
import { useRef } from "react";

function StandardCard({ theme, params }) {
    const router = useRouter();
    const container = useRef(null);
    const box = useRef(null);

    const mouseover = () => {
        box.current.classList.remove(styles.animationBackClass);
        box.current.classList.add(styles.animation);
        container.current.style.borderColor = "rgba(158,231,78, 0.5)";
    }

    const mouseleave = () => {
        box.current.classList.remove(styles.animation);
        box.current.classList.add(styles.animationBackClass);
        container.current.style.borderColor = "#cccccc";
    }

    return (
        <div id="standard-container" ref={container} onMouseOver={mouseover} onMouseLeave={mouseleave}>
            <a href={params.url}>
                <div className={theme === 'light' ? styles.standardCard : `${styles.dark}`} onClick={() => { }} key={Math.random()}>
                    <div className={styles.standardCardHead}>
                        <div className={styles.standardCardHeaderTitle}>
                            {params.type || "develop"}
                        </div>
                        <div className={styles.standardCardHeaderSubtitle}>
                            {params.name || "Standard Card"}
                        </div>
                        <div className={styles.standardCardDescription}>
                            {params.description || "无介绍！👌"}
                        </div>
                        <div className={styles.divider}></div>
                        <div className={styles.footer}>
                            <div className={styles.footerLeft}>
                                {/* <div className={styles.footerLeftAuthor} style={theme === 'light' ? {} : { color: "white" }}>
                                    {params.author || "Zevin"}
                                </div> */}
                                {<div className={styles.footerLeftDate} style={theme === 'light' ? {} : { color: "white" }}>
                                    {params.date.toString().slice(0, 25) || Date()}
                                </div>}
                            </div>
                            <div className={styles.footerRight}>
                                <div className={styles.footerRightViews} style={theme === 'light' ? {} : { color: "white" }}>
                                    Views:{params.views || "99+"}
                                </div>
                            </div>
                            <div className={styles.footerRight}>
                                <div className={styles.footerRightViews} style={theme === 'light' ? {} : { color: "white" }}>
                                    comments:{params.comments}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
            <div className={styles.standardBox} ref={box}></div>
        </div>
    );
}

export default StandardCard;
