import { useEffect } from 'react';
import { CardLayout } from './CardLayout';

import styles from './index.module.scss'

export function CardPanel({ params, theme }) {
    return (
        <>
            <a className={styles.link} href={params.url}>
                <CardLayout theme={theme} headTitle={params.name} subTitle={params.sign}
                    extraInfo={{ name: params.arch, updated: params.createTime || "2023/7/12" }} backgroundColor={params.backgroundColor}></CardLayout>
            </a>
        </>
    );
}