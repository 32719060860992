import styles from './index.module.scss'

export function CardLayout({ theme, headTitle, subTitle, extraInfo, backgroundColor }) {

    return (
        <div>
            <div className={styles.cardImage} style={{backgroundColor: backgroundColor}}></div>
            <div className={theme === 'light' ? styles.cardBox : `${styles.dark}`}>
                {/* 大标题 */}
                <div className={styles.cardHeadingTitle}>{headTitle}</div>
                {/* 副标题 */}
                <div className={styles.cardSubTitle}>{subTitle}</div>
                {/* 提示信息 */}
                <div className={styles.cardExtraInfo}>
                    <span>{extraInfo.name}</span>
                    <span>updated:{extraInfo.updated}</span>
                </div>
            </div>
        </div>
    )

}