import styles, { dark } from "../styles/Home.module.css";
import { useRouter } from "next/router";
import { Base64 } from "js-base64";
import React, { useState, useEffect, useRef } from "react";
import { Modal, Input } from "antd";
import StandardCard from "../components/StandardCard/StandardCard";
import { CardPanel } from "@/components/CardPanel";
import axios from "axios";

const Home = React.memo(function ({ theme, children }) {
    let { data } = children;
    data = data.data;
    let contentDiv = null;
    const router = useRouter();
    let date = new Date();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (isModalVisible === true) {
            setIsModalVisible(false);
        }
    }, [isModalVisible]);

    // 初次加载请求
    useEffect(() => {
        // console.log("开始检测", date.getDate());
        if (date.getFullYear() == "2023" && date.getMonth() == "5" && date.getDate() == "19") {
            router.replace("/activities")
        } else {

        }
    }, []);

    // 路由跳转
    function toContent(title) {
        router.push({
            pathname: "/content", query: {
                title: Base64.encodeURI(title), verifyCode: Base64.encode("item.verifyCode"),
            }
        })
    };

    // 输入框校验
    const validity = (value) => {
        if (value.nativeEvent.target.value === "we") {
            router.push({
                pathname: "/" + "movie", // 跳转到指定路径
                query: {
                    title: Base64.encode("item.classifyId"), verifyCode: Base64.encode("item.verifyCode"),
                },
            });
            return false;
        }
    };

    // 内容区域
    contentDiv = data.map((item, index) => {
        return (<div className={styles.card} key={Math.random()} onClick={() => {
            if (item.link !== undefined && item.link !== null) {
                router.push(item.link);
            } else {
                toContent(item.name);
            }
        }}>
            <StandardCard theme={theme} params={item}></StandardCard>
        </div>);
    });

    // 添加站点
    let siteItem = [
        {
            name: "彩牛养生",
            sign: "彩牛养生是知名的养生门户，为广大网民提供养生保健知识，包括中医养生，食疗养生，运动养生，健康养生方法，春夏秋冬四季养生小常识等内容，是您健康生活的好伴侣！",
            url: "https://www.cnys.com/gxzy.html",
            picture: "/Nomessages.svg",
            arch: "无",
            showTag: false,
            backgroundColor: "rgba(255,255,255, 0.5)",
            createTime: "2020-04-15"
        },
        {
            name: "积分商城",
            sign: "零积分，兑抱枕",
            url: "https://developer.aliyun.com/trainingcamp/6c149bd19b21479f881d0fcd731e23b3?taskCode=bigdata2209&recordId=4293287&userCode=i7y0nza9&share_source=copy_link",
            picture: "/Nomessages.svg",
            arch: "阿里云开发者社区",
            showTag: false,
            backgroundColor: "rgba(255,255,255, 0.5)",
            createTime: "2021-09-22"
        },
        {
            name: "electronics-tutorials",
            sign: "电子学习教程",
            url: "https://www.electronics-tutorials.ws",
            picture: "/Nomessages.svg",
            arch: "无",
            showTag: false,
            backgroundColor: "rgba(255,255,255, 0.5)",
            createTime: "2020-06-28",
        },
        {
            name: "TI",
            sign: "TI 高精度实验室",
            url: "https://edu.21ic.com/ti-precision-labs-overview",
            picture: "/Nomessages.svg",
            arch: "德州仪器",
            showTag: false,
            backgroundColor: "rgba(255,255,255, 0.5)",
            createTime: "2020-05-12"
        },
        {
            name: "无线安全",
            sign: "网络安全",
            url: "https://www.freebuf.com/articles/wireless",
            picture: "/Nomessages.svg",
            arch: "freebuf",
            showTag: false,
            backgroundColor: "rgba(255,255,255, 0.5)",
            createTime:
                "2021-04-11",
        },
        {
            name: "web安全",
            sign: "网络安全",
            url: "https://www.freebuf.com/articles/web",
            picture: "/Nomessages.svg",
            arch: "freebuf",
            showTag: false,
            backgroundColor: "rgba(255,255,255, 0.5)",
            createTime: "2021-04-11"
        }, {
            name: "书栈",
            sign: "知识充能",
            url: "https://www.bookstack.cn/",
            picture: "/Nomessages.svg",
            arch: "bookstack",
            showTag: false,
            backgroundColor: "rgba(255,255,255, 0.5)",
            createTime: "2021-04-11",
        },
        {
            name: "comiru",
            sign: "job",
            url: "https://zhaopin.comiru.cn/home",
            picture: "/Nomessages.svg",
            arch: "Comiru日本招聘",
            showTag: false,
            backgroundColor: "rgba(255,255,255, 0.5)",
            createTime: "2023/08/13"
        },
        {
            name: "EASY AI",
            sign: "artificial intelligence",
            url: "https://easyai.tech/ai-definition/machine-learning/",
            picture: "/Nomessages.svg",
            arch: "AI",
            showTag: true,
            backgroundColor: "rgba(255,255,255, 0.5)",
            createTime: "2023/08/13"
        }


    ];
    // 内容
    let contentSite = siteItem.map((item) => {
        return (
            <div className={styles.card} key={Math.random() * 10}>
                <CardPanel params={item} theme={theme}></CardPanel>
            </div>
        );
    });

    const handleOk = () => {
        setShow(false);
        console.log("确定:" + show);
    };

    const handleCancel = () => {
        setShow(false);
        console.log("取消:" + show);
    };

    // 主体部分
    return (
        <div className={`${styles.index}`}>
            <h3 className={styles.outsideTitle}>{data.title1 || "博客"}</h3>
            {/* <div className={styles.grid}>{contentDiv}</div> */}
            <div className={styles.grid} onClick={(e) => {
                console.log(e.target);
            }}>
                {/*<h3 style={{margin: "100px", color: "#2d2d2d"}}>修复中...</h3>*/}
                {contentDiv}
            </div>
            <h3 className={styles.outsideTitle}>{data.title2 || "站点"}</h3>
            <div className={styles.grid}>{contentSite}</div>
            <Modal
                title="Basic Modal"
                visible={show}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <div>
                    <Input
                        placeholder="请请输入校验码"
                        onChange={(e) => {
                            validity(e);
                        }}
                    ></Input>
                </div>
            </Modal>
        </div>
    );
});

export default Home;

export async function getServerSideProps() {
    const result = await axios.get(
        "https://api.intellieff.top/.netlify/functions/blog?action=getBlogs",
        {
            headers: {
                acccept: "application/json",
            }
        }
    );

    return {
        props: {
            page: "index",
            data: result.data,
        },
    };
}
